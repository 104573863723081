import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { MainWrapper, Layout, PageHeader, SEO } from "components"

const PageWrap = styled.div`
  color: var(--zombie);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  h3 {
    margin: 20px 0 10px 0;
    color: var(--black);
    font-family: "Oswald", sans-serif;
  }
  h1 {
    color: var(--black);
    font-family: "Oswald", sans-serif;
  }
`

export default function Accessibility({
  data: {
    site: { info },
  },
}) {
  return (
    <Layout>
      <SEO title="Accessibility" />
      <PageHeader title="Accessibility">
        <StaticImage
          src="../images/menu-page-header.jpg"
          placeholder="blurred"
          alt="Menu"
          quality="100"
        />
      </PageHeader>
      <div style={{ background: "var(--whiteChalk)" }}>
        <MainWrapper>
          <section style={{ padding: "150px 0" }}>
            <PageWrap>
              <div className="row">
                <div className="privacy-content">
                  <div className="text-center">
                    <h1 className="sans">Accessibility Policy & Statement</h1>
                    <div className="d-flex justify-content-center">
                      <p className="sans p-update">
                        Last updated: {info.privacyAccessibilityTermsUpdateDate}
                      </p>
                    </div>
                  </div>
                  <div className="p-content">
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">General</h3>
                    </div>
                    <p>
                      <b>{info.title} </b> strives to ensure that its services
                      are accessible to people with disabilities.{" "}
                      <b>{info.title} </b> has invested a significant amount of
                      resources to help ensure that its website is made easier
                      to use and more accessible for people with disabilities,
                      with the strong belief that every person has the right to
                      live with dignity, equality, comfort and independence.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">
                        Accessibility on <b>{info.title} </b>
                      </h3>
                    </div>
                    <p>
                      <b>{info.title} </b> makes available the UserWay Website
                      Accessibility Widget that is powered by a dedicated
                      accessibility server. The software allows{" "}
                      <b>{info.title} </b> to improve its compliance with the
                      Web Content Accessibility Guidelines (WCAG 2.1).
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Enabling the Accessibility Menu</h3>
                    </div>
                    <p>
                      The <b>{info.title} </b> accessibility menu can be enabled
                      by clicking the accessibility menu icon that appears on
                      the corner of the page. After triggering the accessibility
                      menu, please wait a moment for the accessibility menu to
                      load in its entirety.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Disclaimer</h3>
                    </div>
                    <p>
                      <b>{info.title}</b> continues its efforts to constantly
                      improve the accessibility of its site and services in the
                      belief that it is our collective moral obligation to allow
                      seamless, accessible and unhindered use also for those of
                      us with disabilities. Despite our efforts to make all
                      pages and content on <b>{info.title} </b> fully
                      accessible, some content may not have yet been fully
                      adapted to the strictest accessibility standards. This may
                      be a result of not having found or identified the most
                      appropriate technological solution.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Here For You</h3>
                    </div>
                    <p>
                      If you are experiencing difficulty with any content on{" "}
                      <b>{info.title} </b> or require assistance with any part
                      of our site, please contact us during normal business
                      hours as detailed below and we will be happy to assist.
                    </p>
                    <div className="d-flex justify-content-left">
                      <h3 className="sans">Contact Us</h3>
                    </div>
                    <p>
                      If you have any questions about this Privacy Policy, You
                      can contact us: <br /> <br /> * By email:{" "}
                      <a className="con-hover" href={`mailto:${info.email}`}>
                        {info.email}
                      </a>{" "}
                      <br />
                      <br /> * By visiting on our website:{" "}
                      <Link to="/">{info.siteUrl}</Link>
                      <br />
                      <br /> * By phone number:{" "}
                      <a className="con-hover" href={`tel:${info.phone}`}>
                        {info.phone}
                      </a>
                      <br />
                      <br /> * By mail: &nbsp;{info.address}
                    </p>
                  </div>
                </div>
              </div>
            </PageWrap>
          </section>
        </MainWrapper>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      info: siteMetadata {
        title
        phone
        email
        siteUrl
        address
        privacyAccessibilityTermsUpdateDate
      }
    }
  }
`
